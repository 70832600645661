import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAppDispatch } from './Store/store';
import { getUserInfo, setLoader, getToken } from './Store/Features/configSlice';
import Lazy from './Components/Lazy';


const Home = Lazy(() => import('./Pages/Home'));
const NotFound = Lazy(() => import('./Pages/NotFound'));
const Login = Lazy(() => import('./Pages/Login'));
const Register = Lazy(() => import('./Pages/Register'));
const ForgotPassword = Lazy(() => import('./Pages/ForgotPassword'));
const Dashboard = Lazy(() => import('./Pages/Dashboard'));
const SenderNames = Lazy(() => import('./Pages/SenderNames'));
const TransactionHistory = Lazy(() => import('./Pages/TransactionHistory'));
const WebTool = Lazy(() => import('./Pages/WebTool'));
const BuyCredits = Lazy(() => import('./Pages/BuyCredits'));
const Docs = Lazy(() => import('./Pages/Docs'));
const Terms = Lazy(() => import('./Pages/Terms'));
const Privacy = Lazy(() => import('./Pages/Privacy'));
const PaymentCallback = Lazy(() => import('./Pages/PaymentCallback'));
const PasswordReset = Lazy(() => import('./Pages/PasswordReset'));

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(getToken());
    dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/password/reset/:token' element={<PasswordReset />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/sender-names' element={<SenderNames />} />
          <Route path='/transaction-history' element={<TransactionHistory />} />
          <Route path='/web-tool' element={<WebTool />} />
          <Route path='/buy-credits' element={<BuyCredits />} />
          <Route path='/docs' element={<Docs />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/payment/callback' element={<PaymentCallback />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
