import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import axios from "axios";

interface ConfigState {
    otp: {
        modal: boolean;
        challengeId: string;
        until: string;
    },
    loader: boolean;
    toast: {
        enable: boolean;
        type?: string;
        header?: string;
        body: string;
    };
    token: string;
    signedIn: boolean;
    user: {
        email: string;
        firstname: string;
        lastname: string;
        company: string;
        status: string;
        role: string;
        balance: 0;
        threshold: number;
        verified_at: string;
        created_at: string;
    }
}

const initialState: ConfigState = {
    otp: {
        modal: false,
        challengeId: '',
        until: ''
    },
    loader: false,
    toast: {
        enable: false,
        type: 'success',
        header: 'Message Notification',
        body: ''
    },
    token: '',
    signedIn: false,
    user: {
        email: '',
        firstname: '',
        lastname: '',
        company: '',
        status: '',
        role: '',
        balance: 0,
        threshold: 0,
        verified_at: '',
        created_at: ''
    }
}

export const getUserInfo = createAsyncThunk(
    'config/user',
    async (thunkAPI, { getState }) => {
        const state = getState() as { config: { token: string } };
        const token = state.config.token;

        if (!token) {
            return {
                signedIn: initialState.signedIn,
                user: initialState.user
            }
        }
        try {
            const response = await axios({
                url: process.env.REACT_APP_API_BASE_URL + '/v1/auth/user',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (response.status === 200) {
                const user = response.data;
                const signedIn = true;

                return { user, signedIn };
            }
        } catch (error) {
            console.log(error);

        }

        return {
            signedIn: initialState.signedIn,
            user: initialState.user
        }
    }
);

export const ConfigSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setOtp: (state, action: PayloadAction<ConfigState['otp']>) => {
            state.otp = action.payload;
        },
        setLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
        setToast: (state, action: PayloadAction<ConfigState['toast']>) => {
            state.toast.enable = action.payload.enable;
            state.toast.body = action.payload.body;

            if (action.payload.type) {
                state.toast.type = action.payload.type;
            }

            if (action.payload.header) {
                state.toast.header = action.payload.header;
            }
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;

            const encyptedToken = CryptoJS.AES.encrypt(action.payload, process.env.REACT_APP_KEY || '').toString();
            sessionStorage.setItem('token', encyptedToken);
        },
        getToken: (state) => {
            if (sessionStorage.getItem('token')) {
                const encryptedToken = sessionStorage.getItem('token') || '';
                const token = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_KEY || '').toString(CryptoJS.enc.Utf8);

                state.token = token;
                state.signedIn = true;
            }
        },
        clearToken: (state) => {
            if (sessionStorage.getItem('token')) {
                sessionStorage.removeItem('token');
            }

            state.signedIn = initialState.signedIn;
            state.user = initialState.user;
            state.token = initialState.token;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.signedIn = action.payload.signedIn;
            state.user = action.payload.user;

            if (!action.payload.signedIn) {
                sessionStorage.removeItem('token');
            }

            state.loader = false;
        });
    }
});

export default ConfigSlice.reducer;
export const { setOtp, setLoader, setToast, setToken, clearToken, getToken } = ConfigSlice.actions;