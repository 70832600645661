import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface Item {
    id: string;
    type: string;
    payment_method: string;
    description: string;
    amount: string;
    status: string;
    created_date: string;
}

interface TransactionState {
    data: Array<Item>
}

const initialState: TransactionState = {
    data: []
};

export const fetchTransactions = createAsyncThunk(
    'transactions/fetch',
    async (limit: number, { getState }) => {
        const state = getState() as { config: { token: string } };

        try {
            const response = await axios({
                url: process.env.REACT_APP_API_BASE_URL + '/v1/transactions',
                method: 'GET',
                params: {
                    limit
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.config.token
                }
            });

            if (response.status === 200) {
                const { data } = response;
                return { data };
            }
        } catch (error) {

        }

        return {
            data: initialState.data
        }
    }
);

export const TransactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransactions.fulfilled, (state, action) => {
            state.data = action.payload.data;
        });
    }
});

export default TransactionSlice.reducer;
// export const { } = SenderNameSlice.actions;