import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoader } from "./configSlice";
import axios from "axios";

interface PaymentState {
    paymentMethod: string;
    amount: number;
    fee: number;
    total: number;
    redirect: string;
    disabled: boolean;
    details: {
        card_number: string;
        exp_month: number;
        exp_year: number;
        cvc: string;
    };
    billing: {
        name: string;
        email: string;
        phone: string;
    };
    transactionId: string;
    transaction: {
        id: string;
        type: string;
        payment_method: string;
        description: string;
        amount: string;
        status: string;
        created_date: string;
    };
}

interface Attributes {
    type: string;
    details?: object;
    billing?: object;
};

const initialState: PaymentState = {
    paymentMethod: 'card',
    amount: 500.00,
    fee: 0.00,
    total: 0.00,
    redirect: '',
    disabled: true,
    details: {
        card_number: '',
        exp_month: 0,
        exp_year: 0,
        cvc: '',
    },
    billing: {
        name: '',
        email: '',
        phone: ''
    },
    transactionId: '',
    transaction: {
        id: '',
        type: '',
        payment_method: '',
        description: '',
        amount: '',
        status: '',
        created_date: ''
    }
}

export const getPaymentFee = createAsyncThunk(
    'payment/fee',
    async (thunkAPI, { getState }) => {
        const state = getState() as { config: { token: string }, payment: PaymentState };
        let { paymentMethod, amount, fee, total } = state.payment;

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_API_BASE_URL}/v1/transactions/fee/${paymentMethod}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${state.config.token}`
                },
                params: {
                    amount
                }
            });

            if (response.status === 200) {
                const data = response.data;

                fee = data.fee;
                total = data.total;
            }
        } catch (error) {
            console.log(error);
        }

        return {
            fee,
            total
        }
    }
);

export const createPayment = createAsyncThunk(
    'payment/create',
    async (thunkAPI, { getState, dispatch }) => {
        dispatch(setLoader(true));

        const state = getState() as { config: { token: string }, payment: PaymentState };
        const transaction = await axios({
            url: `${process.env.REACT_APP_API_BASE_URL}/v1/transactions`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.config.token}`
            },
            data: {
                payment_method: state.payment.paymentMethod,
                amount: state.payment.amount
            }
        });

        const { ti, pi, ck, pk } = transaction.data;
        let type = state.payment.paymentMethod;

        try {

            if (type === 'grabpay') {
                type = 'grab_pay'
            }

            let attributes: Attributes = {
                type
            }

            if (type === 'card') {
                attributes.details = state.payment.details;
                attributes.billing = state.payment.billing;
            }

            const paymentMethod = await axios({
                url: `${process.env.REACT_APP_PAYMONGO_URL}/v1/payment_methods`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Basic ${pk}`
                },
                data: {
                    data: {
                        attributes
                    }
                }
            });

            const paymentMethodId = paymentMethod.data.data.id;

            const paymentIntent = await axios({
                url: `${process.env.REACT_APP_PAYMONGO_URL}/v1/payment_intents/${pi}/attach`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Basic ${pk}`
                },
                data: {
                    data: {
                        attributes: {
                            payment_method: paymentMethodId,
                            client_key: ck,
                            return_url: `${window.location.origin}/payment/callback?pid=${ti}`
                        }
                    }
                }
            });

            const data = paymentIntent.data.data;

            if (data.attributes.status === 'awaiting_next_action') {
                return data.attributes.next_action.redirect.url;
            }

        } catch (error) {
            console.log(error);
        }

        return `${window.location.origin}/payment/callback?pid=${ti}`
    }
);

export const inquirePayment = createAsyncThunk(
    'payment/inquire',
    async (thunkAPI, { getState, dispatch }) => {
        dispatch(setLoader(true));

        const state = getState() as { config: { token: string }, payment: PaymentState };
        try {
            const transaction = await axios({
                url: `${process.env.REACT_APP_API_BASE_URL}/v1/transactions/${state.payment.transactionId}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${state.config.token}`
                }
            });

            dispatch(setLoader(false));

            return transaction.data;
        } catch (error) {
            console.log(error);
        }

        dispatch(setLoader(false));
    }
);

export const PaymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPaymentMethod: (state, action: PayloadAction<string>) => {
            state.paymentMethod = action.payload;
        },
        setAmount: (state, action: PayloadAction<number>) => {
            state.amount = action.payload;
        },
        checkDisabled: (state) => {
            let disabled = true;

            if (state.paymentMethod === 'card') {
                if (state.details.card_number &&
                    state.details.cvc &&
                    state.details.exp_month !== 0 &&
                    state.details.exp_year !== 0 &&
                    state.billing.name &&
                    state.billing.email &&
                    state.billing.phone) {
                    disabled = false;
                }
            } else {
                disabled = false;
            }

            if (disabled === false && state.amount < 500) {
                disabled = true;
            }

            state.disabled = disabled;
        },
        setCardDetails: (state, action: PayloadAction<PaymentState['details']>) => {
            state.details = action.payload;
        },
        setBilling: (state, action: PayloadAction<PaymentState['billing']>) => {
            state.billing = action.payload;
        },
        setTransactionId: (state, action: PayloadAction<string>) => {
            state.transactionId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentFee.fulfilled, (state, action: PayloadAction<{ fee: number, total: number }>) => {
            state.fee = action.payload.fee;
            state.total = action.payload.total;
        });

        builder.addCase(createPayment.fulfilled, (state, action: PayloadAction<string>) => {
            state.redirect = action.payload
        });

        builder.addCase(inquirePayment.fulfilled, (state, action: PayloadAction<PaymentState['transaction']>) => {
            state.transaction = action.payload;
        });
    }
});

export default PaymentSlice.reducer;
export const { setPaymentMethod, setAmount, checkDisabled, setCardDetails, setBilling, setTransactionId } = PaymentSlice.actions;