import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ConfigSlice } from "./Features/configSlice";
import { SenderNameSlice } from "./Features/senderNameSlice";
import { TransactionSlice } from "./Features/transactionSlice";
import { ChartSlice } from "./Features/chartSlice";
import { MessageSlice } from "./Features/messageSlice";
import { PaymentSlice } from "./Features/paymentSlice";

export const store = configureStore({
    reducer: {
        config: ConfigSlice.reducer,
        sendernames: SenderNameSlice.reducer,
        transactions: TransactionSlice.reducer,
        chart: ChartSlice.reducer,
        messages: MessageSlice.reducer,
        payment: PaymentSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;