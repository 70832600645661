import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface Item {
    id: string;
    name: string;
    company: string;
    rate: string;
    status: string;
    billing_cycle: string;
    created_date: string;
    next_renewal: string;
}

interface SenderNameState {
    data: Array<Item>
    item: Item,
    ip_whitelist: Array<string>
}

const initialState: SenderNameState = {
    data: [],
    item: {
        id: '',
        name: '',
        company: '',
        rate: '',
        status: '',
        billing_cycle: '',
        created_date: '',
        next_renewal: ''
    },
    ip_whitelist: []
};

export const fetchSenderNames = createAsyncThunk(
    'sendernames/fetch',
    async (limit: number, { getState }) => {
        const state = getState() as { config: { token: string } };

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_API_BASE_URL}/v1/sendernames`,
                method: 'GET',
                params: {
                    limit
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.config.token
                }
            });

            if (response.status === 200) {
                const { data } = response;
                return { data };
            }
        } catch (error) {

        }

        return {
            data: initialState.data
        }
    }
);

export const fetchSenderNameIpWhitelisted = createAsyncThunk(
    'sendernames/fetch-ip-whitelisted',
    async (thunkAPI, { getState }) => {
        const state = getState() as {
            config: {
                token: string
            },
            sendernames: {
                item: {
                    id: string
                }
            }
        };

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_API_BASE_URL}/v1/sendernames/${state.sendernames.item.id}/ip-whitelist`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.config.token
                }
            });

            if (response.status === 200) {
                const { data } = response.data;
                return { data };
            }
        } catch (error) {

        }

        return {
            data: initialState.ip_whitelist
        }
    }
);

export const SenderNameSlice = createSlice({
    name: 'sendernames',
    initialState,
    reducers: {
        setItem: (state, action: PayloadAction<string>) => {
            const item = state.data.find(obj => obj.id === action.payload);

            if (item) {
                state.item = item;
            } else {
                state.item = initialState.item;
            }
        },
        setIpWhitelist: (state, action: PayloadAction<SenderNameState['ip_whitelist']>) => {
            state.ip_whitelist = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSenderNames.fulfilled, (state, action) => {
            state.data = action.payload.data;
        });
        builder.addCase(fetchSenderNameIpWhitelisted.fulfilled, (state, action) => {
            state.ip_whitelist = action.payload.data;
        });
    }
});

export default SenderNameSlice.reducer;
export const { setItem, setIpWhitelist } = SenderNameSlice.actions;