import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setLoader, setToast } from "./configSlice";

interface Item {
    id: string;
    sendername: string;
    destination: number;
    message: string;
    type: string;
    mode: string;
    source: string;
    credits: number;
    status: string;
    created_date: string;
}

interface MessageState {
    data: Array<Item>;
    sender: string;
    destination: string;
    message: string;
    disabled: boolean;
}

const initialState: MessageState = {
    data: [],
    sender: '',
    destination: '',
    message: '',
    disabled: true
};

export const fetchMessages = createAsyncThunk(
    'messages/fetch',
    async (thunkAPI, { getState }) => {
        const state = getState() as { config: { token: string } };

        try {
            const response = await axios({
                url: process.env.REACT_APP_API_BASE_URL + '/v1/messages',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.config.token
                }
            });

            if (response.status === 200) {
                const { data } = response;
                return { data };
            }
        } catch (error) {

        }

        return {
            data: initialState.data
        }
    }
);

export const createMessage = createAsyncThunk(
    'messages/create',
    async (thunkAPI, { getState, dispatch }) => {
        const state = getState() as { config: { token: string }, messages: { sender: string, destination: string, message: string } };
        dispatch(setLoader(true));

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/v1/messages`, {
            sender: state.messages.sender,
            destination: state.messages.destination,
            message: state.messages.message
        }, {
            headers: {
                'Authorization': `Bearer ${state.config.token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            dispatch(setDestination(''));
            dispatch(setMessage(''));
            dispatch(setDisabled(true));

            dispatch(setToast({
                enable: true,
                type: 'success',
                header: 'Success Message',
                body: 'The message/s was successfully submitted.'
            }));
        }).catch((error) => {
            if (error.response.data.code === 422) {
                const errors = error.response.data.errors;
                const errorMessage = errors[Object.keys(errors)[0]];
                dispatch(setToast({
                    enable: true,
                    type: 'danger',
                    header: 'Validation Error Message',
                    body: errorMessage
                }));
            } else {
                dispatch(setToast({
                    enable: true,
                    type: 'danger',
                    header: 'Error Message',
                    body: error.response.data.message
                }));
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
);

export const MessageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setSender: (state, action: PayloadAction<string>) => {
            state.sender = action.payload;
        },
        setDestination: (state, action: PayloadAction<string>) => {
            state.destination = action.payload;
        },
        setMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
        setDisabled: (state, action: PayloadAction<boolean>) => {
            state.disabled = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMessages.fulfilled, (state, action) => {
            state.data = action.payload.data;
        });

        builder.addCase(createMessage.fulfilled, (state, action) => {

        });
    }
});

export default MessageSlice.reducer;
export const { setSender, setDestination, setMessage, setDisabled } = MessageSlice.actions;