import React from "react";
import './index.css';
import { useAppSelector } from "../../Store/store";

interface Props {

}

function Loader(props: Props) {
    const { loader } = useAppSelector((state) => state.config);
    if (loader) {
        return (
            <React.Fragment>
                <div className={'loader-area'}>
                    <center>
                        <div className="loader"></div>
                        <img width={150} src="/logo.svg" alt="logo" />
                    </center>
                </div>
            </React.Fragment>
        );
    }

    return (<React.Fragment></React.Fragment>);
}

export default Loader;