import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface ChartState {
    labels: Array<string>;
    sent: {
        [key: string]: number
    };
    failed: {
        [key: string]: number
    };
}

const initialState: ChartState = {
    labels: ['1990-01-01'],
    sent: {},
    failed: {}
};

export const fetchChart = createAsyncThunk(
    'chart/fetch',
    async (thunkAPI, { getState }) => {
        const state = getState() as { config: { token: string } };

        try {
            const response = await axios({
                url: process.env.REACT_APP_API_BASE_URL + '/v1/charts',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.config.token
                }
            });

            if (response.status === 200) {
                const { labels, sent, failed } = response.data;
                return { labels, sent, failed };
            }
        } catch (error) {

        }

        return initialState
    }
);

export const ChartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChart.fulfilled, (state, action) => {
            state.labels = action.payload.labels;
            state.sent = action.payload.sent;
            state.failed = action.payload.failed;
        });
    }
});

export default ChartSlice.reducer;
// export const { } = SenderNameSlice.actions;